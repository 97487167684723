export const environment = {
    production: false,
    development: true,
    testDevelopment: false,
    resetPasswordURL: "https://dev.accounts.inavista.com/setup-new-password",
    appUrl: 'https://dev-rims.inavista.com/auth',
    appApiUrl: 'https://dev-api-01-rims.inavista.com/api/',
    apiUrl: 'https://dev-webauth.inavista.com/api/',
    redirectUrl: 'https://sso1beta.snapon.com/Account/Login?ReturnUrl=%2Fconnect%2Fauthorize%2Fcallback%3Fclient_id%3DMobileApp.js%26redirect_uri%3Dhttps%253A%252F%252Fmobileappdevtest-designer.azurewebsites.net%252Fauth%26response_type%3Did_token%2520token%26scope%3Dopenid%2520profile%2520Roles%26state%3D16414008400430.ruvtjl5vfu%26nonce%3D16414008400430.usnkl95geh',
    syncfusionLicenseKey: 'Ngo9BigBOggjHTQxAR8/V1NAaF1cXmhIfEx1RHxQdld5ZFRHallYTnNWUj0eQnxTdEFjW31WcHNXQ2RVV0xwVw==',
};