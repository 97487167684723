import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse
} from '@angular/common/http';
import { Observable, map } from 'rxjs';

@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {

  constructor() {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const token: any = localStorage.getItem('access_token');
    request = request.clone({
      headers: request.headers.set('Authorization', `Bearer ${token}`)
    });

    //Temporary solution, need to be fixed later
    if(!request.headers.has('Content-Type')) {
      request = request.clone({ headers: request.headers.set('Content-Type', 'application/json') });
    } else if(request?.headers.get('Content-Type') == 'FileUpload') {
        request = request.clone({ headers: request.headers.delete('Content-Type') });
    }


    request = request.clone({ headers: request.headers.set('Accept', 'application/json') });
    
    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
              // console.log('event--->>>', event);
          }
          return event;
      })
  );
  }
}